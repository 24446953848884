import React from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { statusOptions, userRolesOptions } from '../../../utils/mics';

const UserFilterForm = (props) => {
    const { handleSearch, handleReset, onFormValuesChange } = props;
    const formik = useFormik({
        initialValues: {
            fullName: '',
            userId: '',
            userRole: '',
            status: '',
            userRole: '',
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                // date_from: values.date_from
                //     ? moment(values.date_from).format('YYYY-MM-DD')
                //     : '',
                // date_till: values.date_till
                //     ? moment(values.date_till).format('YYYY-MM-DD')
                //     : '',
            };
            handleSearch(data);
        },
    });

    const handleClear = () => {
        formik.resetForm();
        onFormValuesChange(formik.values);
        handleReset();
    };

    const handleChange = (e) => {
        formik.handleChange(e);
        if (onFormValuesChange) {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            onFormValuesChange(updatedValues);
        }
    }
    
    const renderFormArea = () => {
        return (
            <div className="grid p-2">
                <div className="p-fluid formgrid grid w-full">
                    <div className="field col-12 md:col-3">
                        <label htmlFor="fullName">Full Name</label>
                        <InputText
                            id="fullName"
                            name="fullName"
                            type="text"
                            value={formik.values.fullName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="userId">User Id</label>
                        <InputText
                            id="userId"
                            name="userId"
                            type="text"
                            value={formik.values.userId}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="userRole">Role</label>
                        <Dropdown 
                            value={formik.values.userRole}
                            onChange={handleChange}
                            options={userRolesOptions} 
                            id="userRole"
                            name="userRole"
                            optionLabel="name"
                            optionValue='code'
                            placeholder="Select Role" 
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="status">Status</label>
                        <Dropdown 
                            value={formik.values.status}
                            onChange={handleChange}
                            options={statusOptions} 
                            id="status"
                            name="status"
                            optionLabel="name"
                            optionValue='name'
                            placeholder="Select status" 
                        />
                    </div>
                </div>
                <div className="grid px-2 py-4">
                    <Button
                        type="submit"
                        label="Search"
                        className="p-button-success mr-2"
                    />
                    <Button
                        type="button"
                        label="Clear"
                        severity="secondary"
                        onClick={handleClear}
                    />
                </div>
            </div>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default UserFilterForm;
