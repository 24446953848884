import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { statusOptions, userRolesOptions } from '../../../utils/mics';

const UserDialog = ({ visible, onHide, user, onSave }) => {
    const [formData, setFormData] = useState(user || {});
    const [isFormValid, setIsFormValid] = useState(false); // For form validation
    const [errors, setErrors] = useState({}); // For error tracking
    const [touched, setTouched] = useState({}); // To track which fields were touched
    const isViewMode = user?.isView || false; // Determine if it's view mode

    useEffect(() => {
        if (visible) {
            // Reset form state when the dialog is opened
            setFormData(user || {});
            setErrors({});
            setTouched({});
            setIsFormValid(true); // Default to valid until proven otherwise
        }
    }, [user, visible]);

    // Validate form inputs to check if all fields are filled
    const validateForm = (data) => {
        const newErrors = {};
        let isValid = true;

        if (!data.fullName) {
            newErrors.fullName = 'Full Name is required';
            isValid = false;
        }
        if (!data.userId) {
            newErrors.userId = 'User ID is required';
            isValid = false;
        }
        if (!data.password) {
            newErrors.password = 'Password is required';
            isValid = false;
        }
        if (!data.userRole) {
            newErrors.userRole = 'User Role is required';
            isValid = false;
        }
        if (!data.status) {
            newErrors.status = 'Status is required';
            isValid = false;
        }

        setErrors(newErrors); // Set validation errors
        setIsFormValid(isValid); // Update form validity
    };

    // Handle input change and re-validate the form
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedData = {
            ...formData,
            [name]: value,
        };
        setFormData(updatedData);
        validateForm(updatedData); // Revalidate the form after input change
    };

    // Handle marking a field as touched
    const handleFieldBlur = (e) => {
        const { name } = e.target;
        setTouched((prevTouched) => ({
            ...prevTouched,
            [name]: true,
        }));
    };

    const handleSave = () => {
        setTouched({
            fullName: true,
            userId: true,
            password: true,
            userRole: true,
            status: true,
        });
        validateForm(formData); // Validate on save attempt

        if (isFormValid) {
            onSave(formData);
            onHide(); // Close the dialog after saving

            // Reset the form after saving
            setFormData({});
            setErrors({});
            setTouched({});
            setIsFormValid(true); // Reset to valid state for the next use
        }
    };

    return (
        <Dialog visible={visible} header={isViewMode ? "View User" : "Update User"} modal onHide={onHide} footer={
            <div>
                <Button label="Close" icon="pi pi-times" onClick={onHide} className="p-button-text" />
                {!isViewMode && (
                    <Button label="Save" icon="pi pi-check" onClick={handleSave} autoFocus disabled={!isFormValid} />
                )}
            </div>
        }>
            <div className="p-fluid">
                <div className="field">
                    <label htmlFor="fullName">Full Name</label>
                    <InputText
                        id="fullName"
                        name="fullName"
                        value={formData.fullName || ''}
                        onChange={handleInputChange}
                        onBlur={handleFieldBlur} // Mark field as touched on blur
                        disabled={isViewMode} // Disable input in view mode
                        className={touched.fullName && errors.fullName ? 'p-invalid' : ''}
                    />
                    {touched.fullName && errors.fullName && <small className="p-error">{errors.fullName}</small>}
                </div>
                <div className="field">
                    <label htmlFor="userId">User ID</label>
                    <InputText
                        id="userId"
                        name="userId"
                        value={formData.userId || ''}
                        onChange={handleInputChange}
                        onBlur={handleFieldBlur}
                        disabled={isViewMode}
                        className={touched.userId && errors.userId ? 'p-invalid' : ''}
                    />
                    {touched.userId && errors.userId && <small className="p-error">{errors.userId}</small>}
                </div>
                <div className="field">
                    <label htmlFor="password">Password</label>
                    <InputText
                        id="password"
                        type='password'
                        name="password"
                        value={formData.password || ''}
                        onChange={handleInputChange}
                        onBlur={handleFieldBlur}
                        disabled={isViewMode}
                        className={touched.password && errors.password ? 'p-invalid' : ''}
                    />
                    {touched.password && errors.password && <small className="p-error">{errors.password}</small>}
                </div>
                <div className="field">
                    <label htmlFor="userRole">User Role</label>
                    <Dropdown
                        value={formData.userRole || ''}
                        onChange={handleInputChange}
                        onBlur={handleFieldBlur}
                        disabled={isViewMode}
                        options={userRolesOptions}
                        id="userRole"
                        name="userRole"
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Select Role"
                        className={touched.userRole && errors.userRole ? 'p-invalid' : ''}
                    />
                    {touched.userRole && errors.userRole && <small className="p-error">{errors.userRole}</small>}
                </div>
                <div className="field">
                    <label htmlFor="status">Status</label>
                    <Dropdown
                        value={formData.status || ''}
                        onChange={handleInputChange}
                        onBlur={handleFieldBlur}
                        disabled={isViewMode}
                        options={statusOptions}
                        id="status"
                        name="status"
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select status"
                        className={touched.status && errors.status ? 'p-invalid' : ''}
                    />
                    {touched.status && errors.status && <small className="p-error">{errors.status}</small>}
                </div>
            </div>
        </Dialog>
    );
};

export default UserDialog;
