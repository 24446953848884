import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { crudSaga, querySaga } from '../../../utils/reduxHelper';

function* resetIntFileLogSaga() {
    yield put(appActions.INTFILELOG_RESET_SUCCESS());
}

function* watchIntFileLogUpdateRequest() {
    yield takeLatest(
        appActions.INTFILELOG_UPDATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'integration/logs/update',
                appActions.INTFILELOG_UPDATE_SUCCESS,
                appActions.INTFILELOG_UPDATE_FAILURE,
                'PUT',
            );
        },
    );
}

function* watchIntFileLogCreateRequest() {
    yield takeLatest(
        appActions.INTFILELOG_CREATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'integration/file',
                appActions.INTFILELOG_CREATE_SUCCESS,
                appActions.INTFILELOG_CREATE_FAILURE,
                'POST',
            );
        },
    );
}

function* watchIntFileLogDeleteRequest() {
    yield takeLatest(
        appActions.INTFILELOG_DELETE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'integration/logs/delete',
                appActions.INTFILELOG_DELETE_SUCCESS,
                appActions.INTFILELOG_DELETE_FAILURE,
                'DELETE',
            );
        },
    );
}

function* watchIntFileLogGetAllRequest() {
    yield takeLatest(
        appActions.INTFILELOG_GET_ALL_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
                'integration/logs/search',
                appActions.INTFILELOG_GET_ALL_SUCCESS,
                appActions.INTFILELOG_GET_ALL_FAILURE,
            );
        },
    );
}


function* watchIntFileLogByIdRequest() {
    yield takeLatest(
        appActions.INTFILELOG_GET_BY_ID_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
               `integration/logs/log/${action?.payload}`,
                appActions.INTFILELOG_GET_BY_ID_SUCCESS,
                appActions.INTFILELOG_GET_BY_ID_FAILURE,
            );
        },
    );
}


export default function* intFileLogWatcher() {
    yield* watchIntFileLogUpdateRequest();
    yield* watchIntFileLogCreateRequest();
    yield* watchIntFileLogGetAllRequest();
    yield* watchIntFileLogDeleteRequest();
    yield* watchIntFileLogByIdRequest();
    yield takeLatest(
        appActions.INTFILELOG_RESET_REQUEST.type,
        resetIntFileLogSaga,
    );
    // Add other watchers here
}

