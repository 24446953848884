import { initialPropConfigState, actions } from "./action";

export const IntFileLogReducer = (state = initialPropConfigState, action) => {
    switch (action.type) {
        case actions.INTFILELOG_GET_ALL_SUCCESS:
            return {
                ...state,
                intfileLogList: action.payload ? action.payload : {}
            };
        case actions.INTFILELOG_UPDATE_SUCCESS:
            return {
                ...state,
                intfilelog: action.payload?.id ? action.payload : {}
            };
        case actions.INTFILELOG_CREATE_SUCCESS:
            return {
                ...state,
                intfilelog: action.payload?.id ? action.payload : {}
            };
        case actions.INTFILELOG_DELETE_SUCCESS:
            return {
                ...state,
                intfilelog: action.payload?.id ? action.payload : {}
            };
        case actions.INTFILELOG_GET_BY_ID_SUCCESS:
            return {
                ...state,
                intFileLogDetail: action.payload ? action.payload : {}
            };
        case actions.INTFILELOG_RESET_SUCCESS:
            return {
                ...state,
                intfilelog: {}
            };
        default:
            return state;
    }
};
