import React from 'react';
import PropTypes from 'prop-types';

const BreadcrumbView = ({ breadcrumbs, onSelect }) => {
  const handleSelect = (breadcrumb, index) => {
    if (onSelect) {
      onSelect(breadcrumb, index);
    }
  };
  return (
    <nav aria-label="breadcrumb-custom" className='breadcrumb-custom'>
      <ol className="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <li
            key={breadcrumb.label}
            className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? 'active' : ''}`}
            aria-current={index === breadcrumbs.length - 1 ? 'page' : undefined}
            onClick={() => handleSelect(breadcrumb, index)}
            style={{
              cursor: index !== breadcrumbs.length - 1 ? 'pointer' : 'default',
              color: index !== breadcrumbs.length - 1 ? '#007bff' : 'inherit',
            }}
          >
            <a className='menuitem-link'>
              {index !== breadcrumbs.length - 1 ? breadcrumb.label : <span>{breadcrumb.label}</span>}
            </a>
            {index !== breadcrumbs.length - 1 && (
              <span className="breadcrumb-separator"> <i className='pi pi-angle-right' /> </span> 
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

BreadcrumbView.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string, // Optional path or value to represent the breadcrumb
    })
  ).isRequired,
  onSelect: PropTypes.func, // Callback when breadcrumb is selected
};

export default BreadcrumbView;
