import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { crudSaga, querySaga } from '../../../utils/reduxHelper';

function* resetFileUploadSaga() {
    yield put(appActions.FILEUPLOAD_RESET_SUCCESS());
}

function* watchFileUploadUpdateRequest() {
    yield takeLatest(
        appActions.FILEUPLOAD_UPDATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                '/integration/file/uploadq/',
                appActions.INTFILELOG_UPDATE_SUCCESS,
                appActions.INTFILELOG_UPDATE_FAILURE,
                'PUT',
            );
        },
    );
}

function* watchFileUploadCreateRequest() {
    yield takeLatest(
        appActions.FILEUPLOAD_CREATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                '/integration/file/uploadq/',
                appActions.FILEUPLOAD_CREATE_SUCCESS,
                appActions.FILEUPLOAD_CREATE_FAILURE,
                'POST',
            );
        },
    );
}

function* watchFileUploadDeleteRequest() {
    yield takeLatest(
        appActions.FILEUPLOAD_DELETE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                '/integration/file/uploadq/',
                appActions.FILEUPLOAD_DELETE_SUCCESS,
                appActions.FILEUPLOAD_DELETE_FAILURE,
                'DELETE',
            );
        },
    );
}

function* watchFileUploadGetAllRequest() {
    yield takeLatest(
        appActions.FILEUPLOAD_GET_ALL_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
                'integration/file/uploadq',
                appActions.FILEUPLOAD_GET_ALL_SUCCESS,
                appActions.FILEUPLOAD_GET_ALL_FAILURE,
            );
        },
    );
}

export default function* intFileUploadWatcher() {
    yield* watchFileUploadUpdateRequest();
    yield* watchFileUploadCreateRequest();
    yield* watchFileUploadGetAllRequest();
    yield* watchFileUploadDeleteRequest()
    yield takeLatest(appActions.FILEUPLOAD_RESET_REQUEST.type, resetFileUploadSaga);
    // Add other watchers here
}
