import {
    generateDefaultState,
    getActionTypes,
} from '../../../utils/reduxHelper';

export const actions = getActionTypes('users', [
    'GET_ALL',
    'UPDATE',
    'CREATE',
    'DELETE',
]);
export const initialUsersState = {
    ...generateDefaultState('users', [
        'GET_ALL',
        'UPDATE',
        'CREATE',
        'DELETE',
    ]),
};
