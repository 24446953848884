import React, { useState, useRef, useEffect, useMemo  } from 'react'
import Sidebar from './components/Sidebar'
import Header from './components/Header';
import "./assets/css/layout/layout.scss";
import Footer from './components/Footer';
import { Toast } from 'primereact/toast';
import { clearToast } from './utils/toastReducer';
import { useDispatch, useSelector } from 'react-redux';
import storage from './utils/storage';

const Layout = (props) => {

    const { children } = props;

    const [isSideBarActive, setIsSideBarActive] = useState(true);

    const toast = useRef(null);  // Reference for the Toast component
    const dispatch = useDispatch();

    // Get toastMessage from Redux store
    const toastMessage = useSelector((state) => state.toast.toastMessage);

    const [userRole, setUserRole] = useState();
    
    const userDetails = useSelector((state) => state?.auth?.userDetails);
    const parsedUserDetails = useMemo(() => {
        return JSON.parse(userDetails || storage.get('userDetails'));
    }, [userDetails]);

    useEffect(() => {
        if (parsedUserDetails?.userRole && parsedUserDetails.userRole !== userRole) {
            setUserRole(parsedUserDetails.userRole);
        }
    }, [parsedUserDetails, userRole]);

    // Show toast whenever there's a new message
    useEffect(() => {
        if (toastMessage && toast?.current) {
            toast.current.show({
                severity: toastMessage?.severity,
                summary: toastMessage?.summary,
                detail: toastMessage?.detail,
                life: 3000, // Auto hide after 3 seconds
            });

            // Clear the toast after showing it
            dispatch(clearToast());
        }
    }, [toastMessage, dispatch]);

    const _toggleSideBar = () => {
        setIsSideBarActive(!isSideBarActive);
    };

    const _logoutClick = () => {};

    return (
        <div>
            <Toast ref={toast} />
            <div className={`header ${isSideBarActive ? 'active' : 'inactive'}`}>
                <Header 
                    toggleSideBar={_toggleSideBar}  // Pass the toggleSideBar function to Header
                    logoutClick={_logoutClick}
                />
            </div>
            <aside className={`sidebar ${isSideBarActive ? 'active' : 'inactive'}`}>
                <Sidebar
                    isSideBarActive={isSideBarActive}
                    toggleSideBar={_toggleSideBar}
                    userRole={userRole}
                />
            </aside>
            <main className={`content ${isSideBarActive ? 'active' : 'inactive'}`}>
                <div className="scrollable-content">
                    {children}
                </div>
            </main>
            <footer className={`footer-container ${isSideBarActive ? 'active' : 'inactive'}`}>
                <Footer/>
            </footer>
           
        </div>
    );
}

export default Layout;
