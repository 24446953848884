import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { querySaga } from '../../../utils/reduxHelper';

function* resetPosJournalSaga() {
    yield put(appActions.POSJOURNAL_RESET_SUCCESS());
}

function* watchPosJournalGetAllRequest() {
    yield takeLatest(
        appActions.POSJOURNAL_GET_ALL_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
                'integration/file/pjo',
                appActions.POSJOURNAL_GET_ALL_SUCCESS,
                appActions.POSJOURNAL_GET_ALL_FAILURE,
            );
        },
    );
}

export default function* posJournalWatcher() {
    yield* watchPosJournalGetAllRequest();
    yield takeLatest(
        appActions.POSJOURNAL_RESET_REQUEST.type,
        resetPosJournalSaga,
    );
    // Add other watchers here
}
