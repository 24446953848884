import {
    generateDefaultState,
    getActionTypes,
} from '../../../utils/reduxHelper';

export const actions = getActionTypes('propConfig', [
    'GET_ALL',
    'UPDATE',
    'CREATE',
    'DELETE',
]);
export const initialPropConfigState = {
    ...generateDefaultState('propConfig', [
        'GET_ALL',
        'UPDATE',
        'CREATE',
        'DELETE',
    ]),
};
