import { initialPropConfigState, actions } from "./action";

export const FileUploadReducer = (state = initialPropConfigState, action) => {
    switch (action.type) {
        case actions.FILEUPLOAD_GET_ALL_SUCCESS:
            return {
                ...state,
                fileUploadList: action.payload ? action.payload : {}
            };
        case actions.FILEUPLOAD_UPDATE_SUCCESS:
            return {
                ...state,
                filepload: action.payload?.id ? action.payload : {}
            };
        case actions.FILEUPLOAD_CREATE_SUCCESS:
            return {
                ...state,
                filepload: action.payload?.id ? action.payload : {}
            };
        case actions.FILEUPLOAD_DELETE_SUCCESS:
            
            return {
                ...state,
                
                filepload: action.payload?.id ? action.payload : {}
            };
        case actions.FILEUPLOAD_RESET_SUCCESS:
            return {
                ...state,
                filepload: {}
            };
        default:
            return state;
    }
};
