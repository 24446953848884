import React, { useState, useEffect, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import RenderTooltipTemplate from './renderTooltipTemplate';
import ExportExcel from './ExportExcel';

const PrimeTable = (props) => {
    const { 
        list, 
        columns, 
        actionColumn, 
        linkColumn, 
        onFilterChangeCallback, 
        tableName,
        isLazy // New prop for controlling lazy loading
    } = props;

    const [totalRecords, setTotalRecords] = useState(list?.totalRecords || 0);
    const [rowData, setRowData] = useState(list?.data || []);
    const [lazyState, setLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "createdOn",
        sortOrder: -1
    });

    // Update table data when 'list' changes
    useEffect(() => {
        if (list?.data) {
            setRowData(list?.data);
            setTotalRecords(list?.totalRecords);
        } else {
            setRowData([]);
            setTotalRecords(0);
        }
    }, [list]);

    const handleTableChange = useCallback((event) => {
        if (isLazy) {
            const newState = {
                ...lazyState,
                first: event.first !== undefined ? event.first : lazyState.first,
                rows: event.rows || lazyState.rows,
                page: (event.page + 1) || lazyState.page,
                sortField: event.sortField || lazyState.sortField,
                sortOrder: event.sortOrder || lazyState.sortOrder
            };
            setLazyState(newState);
            onFilterChangeCallback(newState);
        }
    }, [lazyState, onFilterChangeCallback, isLazy]);

    const renderActionTemplate = (rowData) => actionColumn(rowData);
    const renderLinkTemplate = (rowData) => linkColumn(rowData);

    // Function to convert JSON data with nested objects to strings
    const formatData = (data) => {
        if (tableName === 'configuration') {
            return data.map(item => ({
                ...item,
                // Convert the propValue JSON object to a string
                propValue: item.propValue ? JSON.stringify(item.propValue, null, 2) : item.propValue
            }));
        } else {
            return data;
        }
    };

    return (
        <>
            <Tooltip target="button" position="bottom" />

            <div className="d-flex flex-row justify-content-end gap-10 mb-3">
                <ExportExcel 
                    data={rowData} 
                    fileName={`dxp_sap_${tableName}`} 
                    formatData={formatData} 
                />
            </div>

            <DataTable
                lazy={isLazy}  // Conditionally apply lazy loading
                showGridlines
                first={isLazy ? lazyState.first : 0}  // Use lazy state if lazy loading is enabled
                rows={isLazy ? lazyState.rows : 10}  // Conditionally control rows per page
                totalRecords={totalRecords}
                onPage={isLazy ? handleTableChange : undefined}  // Apply only when lazy loading is true
                onSort={isLazy ? handleTableChange : undefined}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                value={rowData}
                columnResizeMode="fit"
                paginator
                rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                emptyMessage="Your search does not retrieve any data. Please search again using another criteria."
            >
                {columns.map((column) => (
                    <Column
                        key={column.field}
                        field={column.field}
                        header={column.header}
                        sortable={column.sortable}
                        style={{ width: column.width, minWidth: '80px', maxWidth: column.maxWidth ? column.maxWidth :  '150px' }}
                        body={(rowData) => <RenderTooltipTemplate rowData={rowData} field={column.field} header={column.header} />}
                    />
                ))}
                {actionColumn && (
                    <Column 
                        header="Actions" 
                        body={renderActionTemplate} 
                        style={{ width: '100px' }} 
                    />
                )}
                {linkColumn && (
                    <Column 
                        header="" 
                        body={renderLinkTemplate} 
                    />
                )}
            </DataTable>
        </>
    );
};

export default PrimeTable;
