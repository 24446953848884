import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { crudSaga, querySaga } from '../../../utils/reduxHelper';

function* resetUsersSaga() {
    yield put(appActions.USERS_RESET_SUCCESS());
}

function* watchPropConfigUpdateRequest() {
    yield takeLatest(
        appActions.USERS_UPDATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'sys/propconfig',
                appActions.USERS_UPDATE_SUCCESS,
                appActions.USERS_UPDATE_FAILURE,
                'PUT',
            );
        },
    );
}

// function* watchPropConfigCreateRequest() {
//     yield takeLatest(
//         appActions.USERS_CREATE_REQUEST.type,
//         function* (action) {
//             yield* crudSaga(
//                 action,
//                 'sys/propconfig',
//                 appActions.USERS_CREATE_SUCCESS,
//                 appActions.USERS_CREATE_FAILURE,
//                 'POST',
//             );
//         },
//     );
// }

// function* watchPropConfigDeleteRequest() {
//     yield takeLatest(
//         appActions.USERS_DELETE_REQUEST.type,
//         function* (action) {
//             yield* crudSaga(
//                 action,
//                 'sys/propconfig',
//                 appActions.USERS_DELETE_SUCCESS,
//                 appActions.USERS_DELETE_FAILURE,
//                 'DELETE',
//             );
//         },
//     );
// }

function* watchPropConfigGetAllRequest() {

    yield takeLatest(
        appActions.USERS_GET_ALL_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
                'sys/propconfig',
                appActions.USERS_GET_ALL_SUCCESS,
                appActions.USERS_GET_ALL_FAILURE,
            );
        },
    );
}

export default function* usersWatcher() {
   yield* watchPropConfigUpdateRequest();
   // yield* watchPropConfigCreateRequest();
    yield* watchPropConfigGetAllRequest();
  //  yield* watchPropConfigDeleteRequest();
    yield takeLatest(
        appActions.USERS_RESET_REQUEST.type,
        resetUsersSaga,
    );
    // Add other watchers here
}
