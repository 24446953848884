import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { fileGroupOptions } from '../../../utils/mics';
import { useLocation } from 'react-router';
import storage from '../../../utils/storage';

const IntFileLogFilterForm = (props) => {
    const { state } = useLocation();
    const [fileId, setFileId] = useState('');

    const { handleSearch, handleReset, onFormValuesChange } = props;

    // Load persisted form values from localStorage only once when the component mounts
    useEffect(() => {
        const savedValues = JSON.parse(localStorage.getItem('formValues'));
        if (savedValues) {
            formik.setValues(savedValues);
        }
    }, []); // Empty dependency array to ensure this only runs once

    const formik = useFormik({
        initialValues: {
            fileId: fileId,
            logGroup: '',
            logType: '',
            date_from: '',
            date_till: '',
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                date_from: values.date_from
                    ? moment(values.date_from).format('YYYY-MM-DD')
                    : '',
                date_till: values.date_till
                    ? moment(values.date_till).format('YYYY-MM-DD')
                    : '',
            };
            handleSearch(data);
            // Save form values to localStorage on search
            storage.set('formValues', JSON.stringify(values));
        },
    });

    // Sync state change with formik's field value (if the fileId is different)
    useEffect(() => {
        if (state?.filterByFileId && state.filterByFileId !== formik.values.fileId) {
            setFileId(state.filterByFileId);
            formik.setFieldValue('fileId', state.filterByFileId); // Update the formik field value only if it's different
            storage.set('formValues', JSON.stringify({...formik.values, fileId: state.filterByFileId}));
        }
    }, [state, formik]); // fileId is removed from the dependency array

    const handleClear = () => {
        formik.resetForm();
        handleReset();
        storage.removeItem('formValues'); // Clear form persistence
    };

    const handleChange = (e) => {
        formik.handleChange(e);
        if (onFormValuesChange) {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            onFormValuesChange(updatedValues);
        }
        // Persist form values to localStorage on every change
        storage.setItem('formValues', JSON.stringify(formik.values));
    };

    const renderFormArea = () => {
        return (
            <div className="grid p-2">
                <div className="p-fluid formgrid grid w-full">
                    <div className="field col-12 md:col-3">
                        <label htmlFor="fileId">File ID</label>
                        <InputText
                            id="fileId"
                            name="fileId"
                            type="text"
                            value={formik.values.fileId}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="logGroup">Log Group</label>
                        <Dropdown
                            value={formik.values.logGroup}
                            onChange={handleChange}
                            options={fileGroupOptions}
                            id="logGroup"
                            name="logGroup"
                            optionLabel="name"
                            optionValue="name"
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">Log Type</label>
                        <InputText
                            id="logType"
                            name="logType"
                            type="text"
                            value={formik.values.logType}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_from">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                </div>
                <div className="grid px-2 py-4">
                    <Button
                        type="submit"
                        label="Search"
                        className="p-button-success mr-2"
                    />
                    <Button
                        type="button"
                        label="Clear"
                        severity="secondary"
                        onClick={handleClear}
                    />
                </div>
            </div>
        );
    };

    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default IntFileLogFilterForm;
