import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import appActions from '../../../appActions';
import { JsonDisplay, statusOptions } from '../../../utils/mics';
import storage from '../../../utils/storage';

const PosJournalDetail = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [record, setRecord] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [visible, setVisible] = useState(false);
    const [fileText, setFileText] = useState('')

    useEffect(() => {
        if (!state) return;
        const { data, isView, isEdit } = state;
        if (data && data.pjofilePath) {
            getFileData(data.pjofilePath)
        }
        setRecord(data || {});
        setIsViewMode(isView || false);
        setIsEditMode(isEdit || false);
    }, [state]);
    const propconfig = useSelector((state) => state.propconfig.propconfig);
    useEffect(() => {
        const { id } = propconfig;
        if (id) {
            setVisible(true);
        }
    }, [propconfig]);

    const getFileData = (filePath) => {
        const URL = window && window.config && window.config.END_POINT;
        const token = storage.getToken()
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${URL}/integration/file/read?filePath=${filePath}`,
            headers: { 
              'Authorization': token
            }
          };
          
          axios.request(config)
          .then((response) => {
            if (response.status == '200') {
                setFileText(JSON.stringify(response.data))
            }
            else {
                setFileText('')
            }
          })
          .catch((error) => {
            setFileText('')
          });
    }

    const handleSubmit = (data) => {
        if (data.id) {
            dispatch(appActions.PROPCONFIG_UPDATE_REQUEST(data));
        } else {
            dispatch(appActions.PROPCONFIG_CREATE_REQUEST(data));
        }
    };

    const formik = useFormik({
        initialValues: {
            id: record?.id || '',
            trxType: record?.trxType || '',
            storeId: record?.storeId || '',
            terminalId: record?.terminalId || '',
            trxId: record?.trxId || '',
            trxDate: record?.trxDate || '',
            trxTime: record?.trxTime || '',
            trxstartTime: record?.trxstartTime || '',
            operatorId: record?.operatorId || '',
            fileId: record?.fileId || 0,
            pjofilePath: record?.pjofilePath || '',
            pjoxmlstatus: record?.pjoxmlStatus || '',
            eodxmlstatus: record?.eodxmlStatus || '',
            error: record?.error || '',
            logtext: record?.logtext || '',
            createdOn: record?.createdOn || '',
            lastUpdated: record?.lastUpdated || '',
            status:
                record && record.status
                    ? statusOptions.find(
                          (status) => status.code === record.status,
                      )
                    : statusOptions.find((status) => status.code === 'Active'),
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const data = {
                ...values,
            };
            if (isEditMode) {
                data['id'] = record.id;
            }
            handleSubmit(data);
        },
    });

    const renderFormArea = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1">
                {(isEditMode || isViewMode) && (
                    <div className="col-4 mb-3">
                        <label htmlFor="id">Id</label>
                        <InputText
                            id="id"
                            name="id"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                )}
                <div className="col-4 mb-3">
                    <label htmlFor="storeId">Store Id</label>
                    <InputText
                        id="storeId"
                        name="storeId"
                        value={formik.values.storeId}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="terminalId">Terminal Id</label>
                    <InputText
                        id="terminalId"
                        name="terminalId"
                        value={formik.values.terminalId}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="trxId">Trx Id</label>
                    <InputText
                        id="trxId"
                        name="trxId"
                        value={formik.values.trxId}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="trxType">Trx Type</label>
                    <InputText
                        id="trxType"
                        name="trxType"
                        value={formik.values.trxType}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="trxDate">Trx Date</label>
                    <InputText
                        id="trxDate"
                        name="trxDate"
                        value={formik.values.trxDate}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="trxTime">Trx Time</label>
                    <InputText
                        id="trxTime"
                        name="trxTime"
                        value={formik.values.trxTime}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="trxstartTime">Trx Start Time</label>
                    <InputText
                        id="trxstartTime"
                        name="trxstartTime"
                        value={formik.values.trxstartTime}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="operatorId">Operator Id</label>
                    <InputText
                        id="operatorId"
                        name="operatorId"
                        value={formik.values.operatorId}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="fileId">File Id</label>
                    <InputText
                        id="fileId"
                        name="fileId"
                        value={formik.values.fileId}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="pjoxmlstatus">Pjo Xml Status</label>
                    <InputText
                        id="pjoxmlstatus"
                        name="pjoxmlstatus"
                        value={formik.values.pjoxmlstatus}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="eodxmlstatus">Eod Xml Status</label>
                    <InputText
                        id="eodxmlstatus"
                        name="eodxmlstatus"
                        value={formik.values.eodxmlstatus}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="createdOn">Created On</label>
                    <InputText
                        id="createdOn"
                        name="createdOn"
                        value={formik.values.createdOn}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="lastUpdated">Last Updated </label>
                    <InputText
                        id="lastUpdated"
                        name="lastUpdated"
                        value={formik.values.lastUpdated}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-12 mb-2">
                    <label htmlFor="pjofilePath">
                        Pjo File Path
                    </label>
                    <InputTextarea
                        id="pjofilePath"
                        name="pjofilePath"
                        rows={5}
                        cols={30}
                        value={formik.values.pjofilePath}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>  
                <div className="col-12 mb-2">
                    <label htmlFor="pjofilePath">
                        File Text
                    </label>
                    <InputTextarea
                        id="fileText"
                        name="fileText"
                        rows={5}
                        cols={30}
                        value={fileText}
                        onChange={formik.handleChange}
                        readOnly
                    />
                </div> 
                <div className="col-12 mb-3">
                    <label htmlFor="logtext">Log Text</label>
                    <InputTextarea
                        id="logtext"
                        name="logtext"
                        rows={5}
                        cols={30}
                        value={formik.values.logtext}
                        onChange={formik.handleChange}
                        readOnly
                    />
                </div>  
            </div>
        );
    };

    const renderMainButton = () => {
        return (
            <>
                {isEditMode &&
                    <Button
                        label={isEditMode ? 'Update' : 'Save'}
                        className="p-button-success mr-3"
                        type="submit"
                    />
                }
                <Button
                    label={isViewMode ? 'Back' : 'Cancel'}
                    className="p-button-secondary"
                    type="button"
                    onClick={() => navigate('/ui/pos-journal')}
                />
            </>
        );
    };

    const handleRedirect = () => {
        dispatch(appActions.PROPCONFIG_RESET_REQUEST());
        navigate('/ui/pos-journal', { replace: true });
    };

    const renderSideBar = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                <div className="col-12 mb-2">
                    <label htmlFor="status">Status</label>
                    <Dropdown
                        id="status"
                        name="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        options={statusOptions}
                        optionLabel="name"
                        placeholder="Select"
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="form_height px-2">
                    <div className="grid pl-3 ml-1">
                        <h2 className="page-header">
                            {isEditMode
                                ? 'Edit Pos Journal'
                                : 'Pos Journal Details'}
                        </h2>
                    </div>
                    <div className="grid px-4 pb-7">
                        <div className="col-9">{renderFormArea()}</div>
                        <div className="col-3 px-3">{renderSideBar()}</div>
                    </div>
                    <div
                        className="grid fixed w-100 bottom-0 surface-900 py-2 justify-content-center"
                        style={{ left: '3%' }}
                    >
                        {renderMainButton()}
                    </div>
                </div>
            </form>
            <Dialog
                className="bg-white pt-4"
                showHeader={false}
                header="Header"
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ textAlign: 'center' }}
            >
                <p>
                    {isEditMode
                        ? 'Pos Journal updated successfully'
                        : 'Pos Journal created successfully'}
                </p>
                <Button
                    type="button"
                    label="Ok"
                    className="mt-2 p-button p-component p-button-success mr-2 mh-4"
                    onClick={handleRedirect}
                />
            </Dialog>
        </>
    );
};

export default PosJournalDetail;
