import React from "react";
import storage from "../utils/storage";

const NameDisplay = () => {
    // Retrieve the user details from storage
    const userDetails = storage.get("userDetails");

    // Initialize fullName to an empty string
    let fullName = '';

    // Check if userDetails is not null and parse it
    if (userDetails) {
        try {
            const parsedUserDetails = JSON.parse(userDetails);
            fullName = parsedUserDetails.fullName || '';
        } catch (error) {
            console.error('Error parsing user details:', error);
        }
    }
    return <span className="UserName">{`${fullName} `}</span>;
};

export default NameDisplay;
