export const CLEAR_TOAST = 'CLEAR_TOAST';
export const SHOW_TOAST = 'SHOW_TOAST';

export const clearToast = () => ({
    type: CLEAR_TOAST,
    type: SHOW_TOAST
});

const initialState = {
    toastMessage: null,
};

export default function toastReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_TOAST:
            return {
                ...state,
                toastMessage: action.payload,
            };
        case CLEAR_TOAST:
            return {
                ...state,
                toastMessage: null,
            };
        default:
            return state;
    }
}
