import { combineReducers } from 'redux';
import { authReducer } from './modules/auth/store/reducer';
import { propConfigReducer } from './modules/propConfig/store/reducer';
import { sysStoreReducer } from './modules/sysStore/store/reducer';
import { IntFileReducer } from './modules/intFile/store/reducer';
import { IntFileLogReducer } from './modules/intFileLog/store/reducer';
import { FileUploadReducer } from './modules/fileUpload/store/reducer';
import { posJournalReducer } from './modules/posJournal/store/reducer';
import toastReducer from './utils/toastReducer';
import { MediaManagementReducer } from './modules/mediaManagement/store/reducer';
import { UsersReducer } from './modules/users/store/reducer';


const rootReducer = combineReducers({
    auth: authReducer,
    propconfig: propConfigReducer,
    sysStore: sysStoreReducer,
    intFile: IntFileReducer,
    intFileLog: IntFileLogReducer,
    fileUpload: FileUploadReducer,
    posJournal: posJournalReducer,
    toast: toastReducer,
    media: MediaManagementReducer,
    user: UsersReducer,
    // Add other reducers here
});

export default rootReducer;
