import React, { useState } from "react";
import NameDisplay from "./NameDisplay";
import ProfileIcon from "../assets/icons/ic_profile.svg";
import ProfileArrow from "../assets/icons/ic_profilearrow.svg";
import storage from "../utils/storage";
import hamburgerIcon from '../assets/icons/hamburger.png';
import { Tooltip } from "primereact/tooltip";

const Header = ({ toggleSideBar }) => {  // Accept toggleSideBar as a prop
    const [settings, setSettings] = useState({
        isThemeVisible: false,
        isNotificationVisible: false,
    });

    const handleLogout = () => {
        storage.clear();
        window.location.href = "/";
    };

    const onSettingsClick = () => {
        setSettings({
            isThemeVisible: !settings.isThemeVisible,
            isNotificationVisible: false,
        });
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light flex item-center ">
            <div className="container-fluid ">
                <div className="row flex justify-between">
                    <div>
                        <Tooltip target={`#tooltip_menu`} content='Toggle Sidebar'/>
                        <img 
                            src={hamburgerIcon} 
                            onClick={toggleSideBar}
                            alt="Toggle Sidebar" 
                            id="tooltip_menu"
                            style={{ width: '30px', height: '30px', marginRight: '8px' }} 
                        />
                    </div>

                    <span className="sidebar-header" id="sidebar-header"></span>
                    <div className="ml-auto"  onClick={onSettingsClick}>
                        <span className="flex item-center">
                            <img
                                alt="ProfileIcon"
                                className="profile_img"
                                src={ProfileIcon}
                            />
                            {<NameDisplay />}
                            <img alt="ArrowDownImg" className="pv-15 ph-15" src={ProfileArrow} />
                        </span>
                    </div>

                    <div
                        className={
                            settings.isThemeVisible
                                ? "slide display-block"
                                : "slide display-none"
                        }
                    >
                        <a
                            className="dropdown-item"
                            href="/"
                            onClick={(event) => {
                                event.preventDefault();
                                handleLogout();
                            }}
                        >
                            Log Out
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
