import React from 'react';
import { Button } from 'primereact/button';

const ExportExcel = ({ data, fileName, formatData }) => {
    const exportToExcel = () => {
        import('xlsx').then((xlsx) => {
            // Format the data if needed
            const formattedData = formatData ? formatData(data) : data;

            // Create a worksheet and workbook for the data
            const worksheet = xlsx.utils.json_to_sheet(formattedData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };

            // Convert workbook to Excel buffer
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            
            // Save the buffer as an Excel file
            saveAsExcelFile(excelBuffer, fileName);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const EXCEL_EXTENSION = '.xlsx';
                
                const blob = new Blob([buffer], { type: EXCEL_TYPE });
                module.default.saveAs(blob, `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
            }
        });
    };

    return (
        <Button 
            type="button" 
            icon="pi pi-file-excel" 
            severity="success" 
            onClick={exportToExcel} 
            disabled={!data || data.length === 0}
            label="Export to Excel" 
        />
    );
};

export default ExportExcel;
