import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import ButtonRefreshIcon from '../../../assets/icons/refresh-svg.svg';
import PrimeTable from '../../../components/PrimeTable';
import UserDialog from './UserDialog';
import UserFilterForm from './UserFilterForm';
import { Tooltip } from 'primereact/tooltip';
// import PropConfigFilter from './PropConfigFilter';

const UsersList = () => {
    
    let navigate = useNavigate();

    const dispatch = useDispatch();

    const { usersList, userDetails } = useSelector((state) => state.user);

    const [usersData, setUsersData] = useState([]);

    useEffect(() => {
        if (usersList && Array.isArray(usersList?.data) && usersList?.data.length > 0) {
            // Update state with the processed data
            setUsersData({...usersList, data: usersList.data[0]?.propValue?.users, totalRecords:  usersList.data[0]?.propValue?.users?.length || usersList.totalRecords} || {});
        } else {
            // Reset or clear data if no valid usersList
            setUsersData([]);
        }
    }, [usersList]);

    const defaultParams = {
        pageno: 1,
        pagesize: 10,
        sort: 'createdOn',
        sortOrder : -1,
        propCode : 'MFT.Users',
        propGroup: 'Auth'
    };

    const [formValues, setFormValues] = useState(defaultParams);
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    
    const handleReset = () => {
        // Reset the form values to default
        setFormValues(defaultParams);
        
        // Reset usersData to the full user list from usersList
        setUsersData({
            ...usersList,
            data: usersList?.data[0]?.propValue?.users,
            totalRecords: usersList?.data[0]?.propValue?.users?.length || usersList.totalRecords
        });
    };
    

    useEffect(() => {
        dispatch(appActions.USERS_GET_ALL_REQUEST(defaultParams));
    }, []);

    const handleRefresh = useCallback(() => {
        dispatch(appActions.USERS_GET_ALL_REQUEST(defaultParams));
    }, [dispatch, defaultParams]);

    useEffect(() => {
        if (userDetails?.action === 'delete') {
            handleRefresh();
        }
    }, [userDetails, handleRefresh]);

    const handleSearch = useCallback(
        (params) => {
            const filteredUsers = usersList?.data[0]?.propValue?.users?.filter((user) => {
                let matches = true;
    
                // Example filters: fullName, userId, and userRole
                if (params.fullName) {
                    matches = user.fullName.toLowerCase().includes(params.fullName.toLowerCase());
                }
                if (matches && params.userId) {
                    matches = user.userId === params.userId;
                }
                if (matches && params.userRole) {
                    matches = user.userRole === params.userRole;
                }
    
                // Additional conditions can be added as per requirements
                return matches;
            });
    
            setUsersData({
                ...usersList,
                data: filteredUsers,
                totalRecords: filteredUsers.length || 0
            });
        },
        [usersList],
    );
    

    const linkToNew = useCallback(() => {
        setSelectedUser({}); // Initialize with empty object for new user
        setDialogVisible(true); // Open dialog for adding new user
    }, []);

    const handleViewRedirect = (record) => {
        const data = usersData?.data?.find((t) => t.userId === record.userId);
        setSelectedUser({ ...data, isView: true }); // Set selected user with isView flag
        setDialogVisible(true); // Open dialog in view mode
    };

    const handleEditRedirect = (record) => {
        const data = usersData?.data?.find((t) => t.userId === record.userId);
        setSelectedUser(data);
        setDialogVisible(true); // Open dialog for editing
    };

    const handleSaveUser = (updatedUser) => {
        const userId = updatedUser.userId;
    
        // Update local state
        setUsersData(prevUsersData => {
            const existingUserIndex = prevUsersData?.data?.findIndex(user => user.userId === userId);
            
            const updatedData = existingUserIndex > -1
                ? prevUsersData.data.map((user, index) =>
                    index === existingUserIndex ? updatedUser : user
                  )
                : [...(prevUsersData.data || []), updatedUser];

            // Construct updatedUserRequest with the new state
            const updatedUserRequest = {
                id: usersList?.data[0]?.id,
                propCode: usersList?.data[0]?.propCode,
                propGroup: usersList?.data[0]?.propGroup,
                propValue: { users: updatedData }
            };
    
            // Dispatch action with the updated request
            dispatch(appActions.USERS_UPDATE_REQUEST(updatedUserRequest));
    
            // Close dialog
            setDialogVisible(false);
    
            // Return updated state to ensure it is used correctly
            return { ...prevUsersData, data: updatedData };
        });
    };
    
    

    const handleFormValuesChange = (values) => {
        setFormValues(values);
    };

    const handleDelete = useCallback((record) => {
        const { userId } = record;
    
        setUsersData(prevUsersData => {
            // Filter the user out of the list
            const updatedUserList = prevUsersData?.data?.filter((user) => user?.userId !== userId);
    
            // Construct the updatedUserRequest using the filtered list
            const updatedUserRequest = {
                id: usersList?.data[0]?.id,
                propCode: usersList?.data[0]?.propCode,
                propGroup: usersList?.data[0]?.propGroup,
                propValue: { users: updatedUserList }
            };
    
            // Dispatch action with the updated request
            dispatch(appActions.USERS_UPDATE_REQUEST(updatedUserRequest));
    
            // Return updated state to ensure it is used correctly
            return { ...prevUsersData, data: updatedUserList };
        });
    }, [dispatch, usersList]);
    

    const renderHeader = () => {
        return (
            <>
                <div className="col-6">
                    <h1 className="page-header flex">
                        Users
                        <img
                            title="Refresh"
                            onClick={handleRefresh}
                            className="refreshtree"
                            src={ButtonRefreshIcon}
                            alt="RefreshButtonImage"
                        />
                    </h1>
                </div>
                <div className="col-6 text-right">
                    <Button
                        label="Add New"
                        icon="pi pi-plus"
                        className="p-button-outlined"
                        onClick={linkToNew}
                    />
                </div>
            </>
        );
    };

    const actionTemplate = useCallback(
        (rowData) => (
            <div className="flex gap-10 justify-content-center">
                <span className="cursor-pointer tp" onClick={() => handleViewRedirect(rowData)} data-pr-tooltip="View">
                    <i className="pi pi-eye text-blue-500 text-base"></i>
                </span>
                <span className="cursor-pointer tp" onClick={() => handleEditRedirect(rowData)} data-pr-tooltip="Edit">
                    <i className="pi pi-pencil text-blue-500 text-base"></i>
                </span>
                <span className="cursor-pointer tp" onClick={() => handleDelete(rowData)} data-pr-tooltip="Delete">
                    <i className="pi pi-times-circle text-base text-danger"/>
                </span>
            </div>
        ),
        [handleViewRedirect, handleEditRedirect, handleDelete],
    );

    const onFilterChangeCallback  = useCallback(
        (event) => {

            const { sortField, sortOrder, rows, page } = event;
            
            const params = {...formValues, pagesize: rows, pageno: page, sort: sortField, sortOrder: sortOrder };

            setFormValues(prevFormValues => ({
                ...prevFormValues,
                sort: sortField, 
                sortOrder: sortOrder,
                pagesize: rows,
                pageno: page
            }));

            handleSearch(params); 
        },

        [handleSearch, formValues],
    );

    const columns = [
        { field: 'fullName', header: 'Full Name', sortable: true },
        { field: 'userId', header: 'User ID', sortable: true},
        { field: 'userRole', header: 'Role ', sortable: true},
        { field: 'status', header: 'Status', sortable: true,width : '90px' },
        // { field: 'createdOn', header: 'Created On', sortable: true, width : '150px' },
        // { field: 'lastUpdated', header: 'Last Updated', sortable: true, width : '150px' },
        // { field: 'lastUpdatedBy', header: 'Last Updated By', sortable: true, width : '150px' },
    ];
    
    const renderList = () => {
        return (
            <>
                <Tooltip target=".tp" position="bottom" />
                <div className="grid px-4 align-items-center my-2">
                    {renderHeader()}
                </div>
                <div className="bg-white mx-4 pv-20 ph-20 br-6 shadow-white">
                    <UserFilterForm
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange} 
                    />
                    <PrimeTable
                        list={usersData}
                        columns={columns}
                        actionColumn={actionTemplate}
                        onFilterChangeCallback={(e) => {
                            onFilterChangeCallback(e);
                        }}
                        tableName='configuration'
                        isLazy={false}
                    />

                    <UserDialog
                        visible={isDialogVisible}
                        onHide={() => setDialogVisible(false)}
                        user={selectedUser}
                        onSave={handleSaveUser}
                    />
                </div>
            </>
        );
    };
    return <div className="form_height">{renderList()}</div>;
};

export default UsersList;
