import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { InputTextarea } from 'primereact/inputtextarea'; 
import appActions from '../../../appActions';
 
const FileUploadDetail = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
 
    const [record, setRecord] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
 
    useEffect(() => {
        if (!state) return;
        const { data, isView, isEdit } = state;
        setRecord(data || {});
        setIsViewMode(isView || false);
        setIsEditMode(isEdit || false);
    }, [state]);
 
    const handleSubmit = (data) => {
        if (data.id) {
            dispatch(appActions.FILEUPLOAD_UPDATE_REQUEST(data));
        } else {
            dispatch(appActions.FILEUPLOAD_CREATE_REQUEST(data));
        }
    }
    const formik = useFormik({
        initialValues: {
            id: record?.id || '',
            fileId: record?.fileId || '',
            storeId: record?.storeId || '',
            uploadGroup: record?.uploadGroup || '',
            uploadScheduleOn: record?.uploadScheduleOn || '',
            uploadText: record?.uploadText || '',
            uploadStatus: record?.uploadStatus || '',
            uploadMethod: record?.uploadMethod || '',
            uploadTime: record?.uploadTime || '',
            error: record?.error || '',
            processed: record?.processed || '',
            uploadStatus: record?.uploadStatus || '',
            status: record?.status || '',
            uploadTime: record?.uploadTime || '',
            error: record?.error || '',
            fileStage: record?.fileStage || '',
            processed: record?.processed || '',
            status: record?.status || '',
            
            createdOn: record?.createdOn || '',
            lastUpdated: record?.lastUpdated || '',
           
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const data = {
                ...values
             };
            if (isEditMode) {
                data['id'] = record.id;
            }
            handleSubmit(data);
        },
    });
    const renderFormArea = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1">
                {(isEditMode || isViewMode) && (
                    <div className="col-4 mb-3">
                    <label htmlFor="id">Id</label>
                    <InputText
                        id="id"
                        name="id"
                        value={formik.values.id}
                        onChange={formik.handleChange}
                        disabled
                    />
                </div>
 
                )}
                <div className="col-4 mb-3">
                    <label htmlFor="fileId">File Id</label>
                    <InputText
                        id="fileId"
                        name="fileId"
                        value={formik.values.fileId}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="storeId">
                    Store Id
                    </label>
                    <InputText
                        id="storeId"
                        name="storeId"
                        value={formik.values.storeId}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>                
                <div className="col-4 mb-2">
                    <label htmlFor="uploadGroup">Upload Group</label>
                    <InputText
                        id="uploadGroup"
                        name="uploadGroup"                      
                        value={formik.values.uploadGroup}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="uploadScheduleOn">Upload Schedule On</label>
                    <InputText
                        id="uploadScheduleOn"
                        name="uploadScheduleOn"
                        value={formik.values.uploadScheduleOn}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                
                <div className="col-4 mb-3">
                    <label htmlFor="uploadStatus">Upload Status</label>
                    <InputText
                        id="uploadStatus"
                        name="uploadStatus"
                        value={formik.values.uploadStatus}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="uploadMethod">Upload Method</label>
                    <InputText
                        id="uploadMethod"
                        name="uploadMethod"
                        value={formik.values.uploadMethod}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                
                <div className="col-4 mb-3">
                    <label htmlFor="uploadTime">Upload Time</label>
                    <InputText
                        id="uploadTime"
                        name="uploadTime"
                        value={formik.values.uploadTime}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="error">Error</label>
                    <InputText
                        id="error"
                        name="error"
                        value={formik.values.error}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="processed">Processed</label>
                    <InputText
                        id="processed"
                        name="processed"
                        value={formik.values.processed}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="status">Status</label>
                    <InputText
                        id="status"
                        name="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="createdOn">Created On </label>
                    <InputText
                        id="createdOn"
                        name="createdOn"
                        value={formik.values.createdOn}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="lastUpdated">Last Updated </label>
                    <InputText
                        id="lastUpdated"
                        name="lastUpdated"
                        value={formik.values.lastUpdated}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="uploadText">Upload Text</label>
                    <InputTextarea
                        id="uploadText"
                        name="uploadText"
                        rows={5}
                        cols={30}
                        value={formik.values.uploadText}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>  
                             
            </div>
        );
    }
 
    const renderMainButton = () => {
        return (
            <>
                { isEditMode && 
                    <Button
                        label={isEditMode ? 'Update' : 'Save'}
                        className="p-button-success"
                        type="submit"
                    />
                }
                <Button
                    label={isViewMode ? 'Back' : 'Cancel'}
                    className="p-button-secondary"
                    type="button"
                    onClick={() => navigate('/ui/file-upload-queue')}
                />
            </>
        );
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="form_height px-2 mt-2">
                    <div className="d-flex flex-row gap-10 align-items-center mb-1">
                        <h2 className="page-header mr-auto">
                        {isEditMode ? 'Edit Integration Schedule Queue' : 'Integration Schedule Queue Details'}
                        </h2>
                        {renderMainButton()}
                    </div>
                    <div className="grid px-2">
                        <div className="col-9">{renderFormArea()}</div>
                    </div>
                </div>
            </form>
        </>
    );
};
 
export default FileUploadDetail;