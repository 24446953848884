import { useState } from "react";
import MediaDirectoryTree from "./MediaDirectoryTree";

// Import icons for different file types
import FolderIcon from '../../../assets/icons/ic_media_folder.svg';
import JsonIcon from '../../../assets/icons/json-file-icon.jpg';   // Custom icon for JSON
import XmlIcon from '../../../assets/icons/xml-file-icon.png';     // Custom icon for XML
import TlogIcon from '../../../assets/icons/tlogs-file-icon.png';   // Custom icon for TLOG
import DefaultFileIcon from '../../../assets/icons/detault-file-icon.png';

const MediaDirectoryItem = ({ item, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (item.directory) {
      setIsOpen(!isOpen);
      onClick(item);
    }
  };

  // Function to get the appropriate icon based on file type or directory status
  const getFileIcon = (item) => {
    if (item.directory) {
      return FolderIcon;
    }

    const fileExtension = item.name.split('.').pop().toLowerCase();

    switch (fileExtension) {
    //   case 'jpg':
    //   case 'jpeg':
    //   case 'png':
    //   case 'gif':
    //     return ImageIcon;
    //   case 'mp4':
    //   case 'mkv':
    //   case 'avi':
    //     return VideoIcon;
    //   case 'pdf':
    //   case 'doc':
    //   case 'docx':
    //     return DocIcon;
      case 'json':
        return JsonIcon;
      case 'xml':
        return XmlIcon;
      case 'tlog':
      case 'tlogs':
        return TlogIcon;
      default:
        return DefaultFileIcon; // Default icon for unknown file types
    }
  };

  return (
    <div style={{ marginLeft: item.directory ? '20px' : '40px' }}>
      <div onClick={handleClick} style={{ cursor: item.directory ? 'pointer' : 'default' }}>
        {/* Display the appropriate icon */}
        <img
          src={getFileIcon(item)}
          alt={item.directory ? 'FolderIcon' : 'FileIcon'}
          style={{ marginRight: '8px', verticalAlign: 'middle' }}
        />
        {item.directory ? (isOpen ? '[-]' : '[+]') : '   '}
        {item.name}
      </div>
      
      {/* Render subdirectories or files if directory is open */}
      {isOpen && item.directory && <MediaDirectoryTree data={item.children} />}
    </div>
  );
};

export default MediaDirectoryItem;
