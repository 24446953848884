import { initialMediaManagementState, actions } from "./action";

export const MediaManagementReducer = (state = initialMediaManagementState, action) => {
    switch (action.type) {
        case actions.MEDIAMANAGEMENT_GET_ALL_SUCCESS:
            return {
                ...state,
                mediaList: action.payload ? action.payload : {}
            };
        case actions.MEDIAMANAGEMENT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                fileContent: action.payload ? action.payload : {}
            };
        case actions.MEDIAMANAGEMENT_RESET_SUCCESS:
            return {
                ...state,
                mediaFile: {}
            };
        default:
            return state;
    }
};
