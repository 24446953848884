import {
    generateDefaultState,
    getActionTypes,
} from '../../../utils/reduxHelper';

export const actions = getActionTypes('mediaManagement', [
    'GET_ALL',
    'UPDATE',
    'CREATE',
    'DELETE',
    'DOWNLOAD'
]);
export const initialMediaManagementState = {
    ...generateDefaultState('mediaManagement', [
        'GET_ALL',
        'UPDATE',
        'CREATE',
        'DELETE',
        'DOWNLOAD'
    ]),
};
