import React, { useEffect, useState } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { useNavigate } from 'react-router';
import moment from 'moment';

const RenderTooltipTemplate = ({ rowData, field, header }) => {

    let navigate = useNavigate();

    let value = rowData[field];

    const [className, setClassName] = useState('');

    // Conditionally add the 'text-danger' class
    useEffect(() => {
        // Check if pjoxmlStatus is not "Y" and createdOn + 20 minutes has passed
        const createdOn = moment(rowData?.createdOn);
        const currentTime = moment();
        const timeDifference = currentTime.diff(createdOn, 'minutes');

        if (rowData?.pjoxmlStatus && rowData.pjoxmlStatus !== 'Y' && timeDifference > 20) {
            setClassName('text-danger');
        } else if(rowData?.status?.toLowerCase() === 'error'){
            setClassName('text-danger');
        }else{
            setClassName('');
        }
    }, [rowData]);

    // Convert JSON object to string if value is an object
    if (value && typeof value === 'object') {
        value = JSON.stringify(value, null, 2); // Pretty print with 2-space indentation
    }

    // Function to handle click and show dialog for FileId column
    const handleFileIdClick = () => {
        if (header === 'FileId') {
            navigate('/ui/integration-file-log', { state: { filterByFileId: rowData[field] } });
        }
    };

    return (
        <div className={`td-tp-wrapper ${className}`}>
            {header === 'FileId' ? (
                <span id={`tooltip_${field}_${rowData.id || rowData.userId}`} onClick={handleFileIdClick} className="clickable">
                    {value}
                </span>
            ) : (
                <span id={`tooltip_${field}_${rowData.id || rowData.userId}`}>{value}</span>
            )}
            <Tooltip target={`#tooltip_${field}_${rowData.id || rowData.userId}`} content={value} />
        </div>
    );
};

export default RenderTooltipTemplate;
