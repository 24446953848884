import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { crudSaga, querySaga } from '../../../utils/reduxHelper';

function* resetPropConfigSaga() {
    yield put(appActions.PROPCONFIG_RESET_SUCCESS());
}

function* watchPropConfigUpdateRequest() {
    yield takeLatest(
        appActions.PROPCONFIG_UPDATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'sys/propconfig',
                appActions.PROPCONFIG_UPDATE_SUCCESS,
                appActions.PROPCONFIG_UPDATE_FAILURE,
                'PUT',
            );
        },
    );
}

function* watchPropConfigCreateRequest() {
    yield takeLatest(
        appActions.PROPCONFIG_CREATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'sys/propconfig',
                appActions.PROPCONFIG_CREATE_SUCCESS,
                appActions.PROPCONFIG_CREATE_FAILURE,
                'POST',
            );
        },
    );
}

function* watchPropConfigDeleteRequest() {
    yield takeLatest(
        appActions.PROPCONFIG_DELETE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'sys/propconfig',
                appActions.PROPCONFIG_DELETE_SUCCESS,
                appActions.PROPCONFIG_DELETE_FAILURE,
                'DELETE',
            );
        },
    );
}

function* watchPropConfigGetAllRequest() {
    yield takeLatest(
        appActions.PROPCONFIG_GET_ALL_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
                'sys/propconfig',
                appActions.PROPCONFIG_GET_ALL_SUCCESS,
                appActions.PROPCONFIG_GET_ALL_FAILURE,
            );
        },
    );
}

export default function* propConfigWatcher() {
    yield* watchPropConfigUpdateRequest();
    yield* watchPropConfigCreateRequest();
    yield* watchPropConfigGetAllRequest();
    yield* watchPropConfigDeleteRequest();
    yield takeLatest(
        appActions.PROPCONFIG_RESET_REQUEST.type,
        resetPropConfigSaga,
    );
    // Add other watchers here
}
