import React from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { statusOptions } from '../../../utils/mics';

const SysStroreFilterForm = (props) => {
    const { handleSearch, handleReset, onFormValuesChange } = props;
    const formik = useFormik({
        initialValues: {
            storeId: '',
            vstoreId: '',
            title: '',
            groupId: '',
            region: '',
            country: '',
            city: '',
            location: '',
            status: '',
            date_from: '',
            date_till: '',
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                date_from: values.date_from
                    ? moment(values.date_from).format('YYYY-MM-DD')
                    : '',
                date_till: values.date_till
                    ? moment(values.date_till).format('YYYY-MM-DD')
                    : '',
            };
            handleSearch(data);
        },
    });

    const handleClear = () => {
        formik.resetForm();
        onFormValuesChange(formik.values);
        handleReset();
    };

    const handleChange = (e) => {
        formik.handleChange(e);
        if (onFormValuesChange) {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            onFormValuesChange(updatedValues);
        }
    }
    
    const renderFormArea = () => {
        return (
            <div className="grid p-2">
                <div className="p-fluid formgrid grid w-full">
                    <div className="field col-12 md:col-3">
                        <label htmlFor="storeId">Store ID</label>
                        <InputText
                            id="storeId"
                            name="storeId"
                            type="number"
                            value={formik.values.storeId}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="storeId">VStore ID</label>
                        <InputText
                            id="vstoreId"
                            name="vstoreId"
                            type="number"
                            value={formik.values.vstoreId}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">Title</label>
                        <InputText
                            id="title"
                            name="title"
                            type="text"
                            value={formik.values.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="groupId">Group ID</label>
                        <InputText
                            id="groupId"
                            name="groupId"
                            type="text"
                            value={formik.values.groupId}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="region">Region</label>
                        <InputText
                            id="region"
                            name="region"
                            type="text"
                            value={formik.values.region}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="country">Country</label>
                        <InputText
                            id="country"
                            name="country"
                            type="text"
                            value={formik.values.country}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="city">City</label>
                        <InputText
                            id="city"
                            name="city"
                            type="text"
                            value={formik.values.city}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="location">Location</label>
                        <InputText
                            id="location"
                            name="location"
                            type="text"
                            value={formik.values.location}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="status">Status</label>
                        <Dropdown 
                            value={formik.values.status}
                            onChange={handleChange}
                            options={statusOptions} 
                            id="status"
                            name="status"
                            optionLabel="name"
                            optionValue='name'
                            placeholder="Select status" 
                        />
                    </div>
                    
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_from">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                </div>
                <div className="grid px-2 py-4">
                    <Button
                        type="submit"
                        label="Search"
                        className="p-button-success mr-2"
                    />
                    <Button
                        type="button"
                        label="Clear"
                        severity="secondary"
                        onClick={handleClear}
                    />
                </div>
            </div>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default SysStroreFilterForm;
