import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import ButtonRefreshIcon from '../../../assets/icons/refresh-svg.svg';
import PrimeTable from '../../../components/PrimeTable';
import ProductFilterForm from './fileUploadFilterForm';
import { Tooltip } from 'primereact/tooltip';

const FileUploadList = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const fileUploadListData = useSelector((state) => state.fileUpload.fileUploadList);
    
    const fileUpload = useSelector((state) => state.fileUpload.fileupload);
    const [visible, setVisible] = useState(false);

    const defaultParams = {
        pageno: 1,
        pagesize: 10,
        sort: 'createdOn',
        sortOrder : -1,
    };

    const [formValues, setFormValues] = useState(defaultParams);

    useEffect(() => {
        if (fileUpload.id) {
            setVisible(true)
        }
    }, [fileUpload])

    useEffect(() => {
        dispatch(appActions.FILEUPLOAD_GET_ALL_REQUEST(defaultParams));
    }, [dispatch]);

    const handleRefresh = useCallback(() => {
        dispatch(appActions.FILEUPLOAD_GET_ALL_REQUEST(defaultParams));
    }, [dispatch, defaultParams]);

    const handleSearch = useCallback(
        (params) => {

            const searchParams = {
                ...params,
                pagesize: params.pagesize || defaultParams?.pagesize,
                pageno: params.pageno || defaultParams?.pageno,
                sort: params.sort || defaultParams?.sort,
                sortOrder: params.sortOrder || defaultParams?.sortOrder,
            };

            dispatch(appActions.FILEUPLOAD_GET_ALL_REQUEST(searchParams));
        },
        [dispatch],
    );

    const handleReset = () => {
        dispatch(appActions.FILEUPLOAD_GET_ALL_REQUEST(defaultParams));
    }

    const handleFormValuesChange = (values) => {
        setFormValues(values);
    };

    const handleViewRedirect = (record) => {
        const data = fileUploadListData?.data?.find(t => t.id === record.id);
        navigate('/ui/file-upload-queue/detail', { state: { data, isView: true }});
    }

    const handleCloseDelete = () => {
        setVisible(false)
        dispatch(appActions.FILEUPLOAD_RESET_REQUEST(defaultParams));
        dispatch(appActions.FILEUPLOAD_GET_ALL_REQUEST(defaultParams));
    }

    const renderHeader = () => {
        return (
            <>
                <div className="col-6">
                    <h1 className="page-header flex">
                        Integration Schedule Queue
                        <img
                            title="Refresh"
                            onClick={handleRefresh}
                            className="refreshtree"
                            src={ButtonRefreshIcon}
                            alt="RefreshButtonImage"
                        />
                    </h1>
                </div>
            </>
        );
    };

    const actionTemplate = useCallback(
        (rowData) => (
            <div className="flex gap-10 justify-content-center">
                <span className="cursor-pointer tp" onClick={() => handleViewRedirect(rowData)} data-pr-tooltip="View">
                    <i className="pi pi-eye text-blue-500 text-base"></i>
                </span>
            </div>
        ),
        [handleViewRedirect],
    );

       const onFilterChangeCallback  = useCallback(
        (event) => {

            const { sortField, sortOrder, rows, page } = event;
            
            const params = {...formValues, pagesize: rows, pageno: page, sort: sortField, sortOrder: sortOrder };

            setFormValues(prevFormValues => ({
                ...prevFormValues,
                sort: sortField, 
                sortOrder: sortOrder,
                pagesize: rows,
                pageno: page
            }));

            handleSearch(params); 
        },

        [handleSearch, formValues],
    );
    
    const columns = [
        { field: 'fileId', header: 'File Id', sortable: true },
        { field: 'storeId', header: 'Store Id', sortable: true },
        { field: 'uploadGroup', header: 'Upload Group', sortable: true },
        { field: 'uploadScheduleOn', header: 'Upload Schedule On', sortable: true },
        { field: 'uploadStatus', header: 'Upload Status', sortable: true },
        { field: 'uploadMethod', header: 'Upload Method', sortable: true },
        { field: 'uploadTime', header: 'Upload Time', sortable: true },
        { field: 'error', header: 'Error', sortable: true },
        { field: 'processed', header: 'Processed', sortable: true },
        { field: 'status', header: 'Status', sortable: true },
        { field: 'createdOn', header: 'Created On', sortable: true },
        // { field: 'lastUpdated', header: 'Last Updated', sortable: true },
        // { field: 'lastUpdatedBy', header: 'Last Updated By', sortable: true },
    ];
    const renderList = () => {
        return (
            <>
                <Tooltip target=".tp" position="bottom" />
                <div className="grid px-4 align-items-center my-2">
                    {renderHeader()}
                </div>
                <div className="bg-white mx-4 pv-20 ph-20 br-6 shadow-white">
                    <ProductFilterForm handleSearch={handleSearch} handleReset={handleReset}  onFormValuesChange={handleFormValuesChange}  />
                    <PrimeTable
                        list={fileUploadListData}
                        columns={columns}
                        actionColumn={actionTemplate}
                        onFilterChangeCallback={(e) => {
                            onFilterChangeCallback(e);
                        }}
                        tableName='integration_schedule_queue'
                        isLazy={true}
                    />
                </div>
                <Dialog
                    className="bg-white pt-4"
                    showHeader={false}
                    header="Header"
                    visible={visible}
                    onHide={() => setVisible(false)}
                    style={{ textAlign: 'center' }}
                >
                    <p>File upload deleted successfully</p>
                    <Button
                        type="button"
                        label="Ok"
                        className="mt-2 p-button p-component p-button-success mr-2 mh-4"
                        onClick={() => handleCloseDelete()}
                    />
                </Dialog>
            </>
        );
    };

    return <div className="form_height">{renderList()}</div>;
};

export default FileUploadList;
